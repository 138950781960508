import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Container from "../components/container";
import TopSection from "../components/top-section";
import MidSection from "../components/mid-section";
import UnderlayTitle from "../components/underlay-title";

export default function Template({ data }: any) {
  const { markdownRemark } = data; // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark;
  const { title, slug } = frontmatter;

  const sections = {
    content: {
      label: title,
      slug: slug,
    },
    top: {
      label: "Back",
      slug: "top",
    },
  };

  return (
    <Layout title={title}>
      <TopSection pageTitle={title} sections={[sections.content]} />
      <MidSection
        className={"bg-white"}
        slug={sections.content.slug}
        prevSection={sections.top}
      >
        <Container className={"justify-center items-center"}>
          <div className={"flex justify-center flex-col "}>
            <UnderlayTitle
              overlay={true}
              size={"small"}
              className={"mb-8 text-primary"}
            >
              {title}
            </UnderlayTitle>

            <div
              className="mt-6 prose prose-blue prose-lg text-gray-500 mx-auto"
              dangerouslySetInnerHTML={{ __html: html }}
            />
          </div>
        </Container>
      </MidSection>
    </Layout>
  );
}

// @ts-ignore
export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        slug
        title
      }
    }
  }
`;
